import { RemixBrowser, useLocation, useMatches } from "@remix-run/react"
import { useEffect, startTransition, StrictMode } from "react"
import { hydrateRoot } from "react-dom/client"
import * as Sentry from "@sentry/remix"

Sentry.init({
  dsn: "https://09ece91fcf014a7591bc596e07e74f89@o471.ingest.sentry.io/4504968089108480",
  release: "SENTRY-RELEASE-PLACEHOLDER",
  includeLocalVariables: true,
  tracesSampleRate: 0,
  integrations: [
    Sentry.browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches,
    }),
  ],
})

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>,
  )
})
